import React, { useMemo } from 'react'
import get from 'lodash/get'
import { formatDateTime, t } from '@/lib/helpers'

import { DataTable } from '@/components/shared/Table'
import {
  AppConfigCheckbox,
  AppConfigInput,
  AppConfigSelect,
} from '@/features/appconfigs/components'

import { AppConfig } from '@/features/appconfigs/types'

type Props = {
  data: AppConfig[]
}

const configOptions = {
  banner_info_type: [
    'eco_actions',
    'articles',
    'news',
    'carbon_reduction',
    'url',
  ],
  banner_info_color: ['red', 'blue', 'green', 'orange'],
}

export const AppConfigTable = ({ data }: Props) => {
  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.app_configs.table.var'),
        accessor: 'var',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return t(`views.admin.app_configs.table.vars.${value}`)
        },
      },
      {
        Header: t('views.admin.app_configs.table.value'),
        accessor: 'value',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')

          if (Object.keys(configOptions).includes(row.var)) {
            return <AppConfigSelect data={row} options={configOptions[row.var]} />
          } else if (row.type === 'string') {
            return <AppConfigInput data={row} />
          }
          return <AppConfigCheckbox data={row} />
        },
      },
      {
        Header: t('views.admin.app_configs.table.updated_at'),
        accessor: 'updated_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
